import PaymentMethodSelector000
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.10/payment-method-selector-000/index.vue';
import consumer_t_od_hotel_order_interim_hotelOrderConfirmDetail
    from '@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_hotelOrderConfirmDetail'
import consumer_t_od_hotel_order_interim_cancelOrder
    from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_cancelOrder";
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import Q from "q";
import moment from "moment";

export default {
    data() {
        return {
            loading: false,
            show: true,
            title: '确认订单信息',
            orderNo: '',
            detail: {
                hotelPicUrl: ''
            },
            milliseconds: 0,
            intervalFun: '',
        }
    },
    components: {PaymentMethodSelector000},
    async activated() {
        const __this = this
        this.orderNo = this.$route.query.orderNo
        await this.getDetail()
        const aPaymentMethodSelector000 = __this.$refs.aPaymentMethodSelector000;
        const orderForPayVos = [
            {
                "orderNo": this.orderNo,
                "businessContent": ""
            },
        ]
        aPaymentMethodSelector000.init({
            mock_data: {
                enable: false,
            },
            count_down: {
                enable: true,
                pay_remaining_seconds: this.detail.milliseconds,
            },
            get_params() {
                const p = {
                    orderForPayVos
                };
                return Q.resolve(p);
            },
            before_pay_event_handler() {
                return Q.resolve();
            },
            user_cancel_pay_event_handler() {
            },
            //支付成功事件处理方法
            pay_successful_event_handler() {
                __this.$message({ type: "success", message: "支付成功！" });
                __this.$router.replace({
                    name: 'admin-pay-success',
                })
            },
            pay_failed_event_handler() {
            },
        });
    },
    deactivated() {
        clearInterval(this.intervalFun)
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        format_time(val) {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
        },
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val).format('mm分ss秒')
                return m
            } else {
                return '00分00秒'
            }
        },
    },
    methods: {
        showMore() {
            this.show = !this.show
        },
        async getDetail() {
            this.loading = true
            let [err, res] = await awaitWrap(consumer_t_od_hotel_order_interim_hotelOrderConfirmDetail({orderNo: this.orderNo}))
            if (err) {
                this.loading = false
                return
            }
            this.loading = false
            this.detail = res.datas
            this.milliseconds = res.datas && res.datas.milliseconds ? res.datas.milliseconds * 1000 || 0 : 0
            clearInterval(this.intervalFun)
            if (this.milliseconds > 0) {
                this.setPayInterval()
            }
        },
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.milliseconds--
                if (this.milliseconds <= 0) {
                    clearInterval(this.intervalFun)
                    this.cancelOrder()
                }
            }, 1000)
        },
        cancelOrder() {
            this.$message({
                type: "warning",
                message: '超时未支付，订单已自动取消'
            })
            consumer_t_od_hotel_order_interim_cancelOrder({orderNo: this.orderNo}).then((res) => {
                this.$router.replace({
                    name: 'admin-hotel-order-detail',
                    query: {
                        orderNo: this.orderNo,
                    }
                })
            });
        },
        submit() {
            const __this = this;
            const selected_payment_channel_entity_list = __this.$refs.aPaymentMethodSelector000.get_selected_payment_channel_entity_list();
            if (selected_payment_channel_entity_list.length <= 0) {
                this.$message({
                    type: "warning",
                    message: '请选择支付方式！'
                })
                return;
            }
            __this.$refs.aPaymentMethodSelector000.trigger_pay();
        },
    }
}
