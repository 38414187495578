// 酒店订单确认页详情 http://yapi.yinzhitravel.com/project/52/interface/api/13626

const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_hotel_order_interim_hotelOrderConfirmDetail = (pParams) => {

    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/hotelOrderConfirmDetail',
        data: pParams,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_hotelOrderConfirmDetail;